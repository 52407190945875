.portfolio-container{
    width: 95%;
    margin: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-bottom: 5rem;
    

}
.portfolio-container h3{
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom: 1rem;
   
}
.portfolio-container h3::after{
    content: "";
    width: 33%;
    height: .5rem;
    background-color: #192a56;
    display: block;
}

.port-box-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}


.port-box{
    width: 30%;
    height: 43vh;
    margin: auto;
    margin-top: .5rem;
    background-color: white;

    box-shadow: 1px 1px 10px 1px #8888;

}
@media(max-width:900px){
    .port-box{
        width: 100%;
        height: 43vh;
        margin: auto;
        margin-top: .5rem;
        background-color: white;
      
        box-shadow: 1px 1px 10px 1px #8888;
    
    }
}
.visit {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    margin-left: 3rem;
   }
   
   .visit {
    width: 12rem;
    height: auto;
   }
   
   .visit .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: black;
    border-radius: 1.625rem;
   }
   
   .visit .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
   }
   
   .visit .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
   }
   
   .visit .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
   }
   
   .visit .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
   }
   
   .visit:hover .circle {
    width: 100%;
   }
   
   .visit:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
   }
   
   .visit:hover .button-text {
    color: #fff;
   }
/* button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 1rem;
    width: 60%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.7rem;
    border-radius: 3rem;
    cursor: pointer;
    background-color: grey;
    color: black;
    border-style: none;
}

button:hover{
    background-color: #f0f8ff;
    transition: .45s ease-in-out;
} */
@media(max-width:900px){
    .port-box{
        width: 85%;
        margin: auto;
    }
   
}

/* @media(max-width:600px){
    .port-box{
        width: 95%;
        margin: auto;
    } */

/* .port-box:hover{
    transform: scale(1.01);
    transition: all 0.1s ease-in-out; 
}  */
.port-box h5{
    margin: 1rem;
    
}
/* .port-box:hover{
    transform: scale(1.04);
    transition: all 0.4s ease-in-out;
} */